<template>
  <Disclosure as="div" v-slot="{ open }">
    <div
      class="w-full has-booking has-additional-menu-content"
      :class="{ 'mobile-menu-opened': open }"
    >
      <header id="identity">
        <div class="logo">
          <h1>
            <a :href="logo.url" class="custom-logo-link" rel="home">
              <img
                :src="logo.dark"
                class="custom-logo dark"
                width="105"
                height="50"
                alt="Starlight"
              />
              <img
                :src="logo.light"
                class="custom-logo light"
                width="105"
                height="50"
                alt="Starlight"
              />
            </a>
          </h1>
        </div>
      </header>
      <div id="site-menu">
        <nav class="nav-menu">
          <!-- <div class="flex justify-end items-end w-full pr-8 mb-2">
            <a
              class="text-black py-2.5 px-3"
              @click="() => {state.currentUser?.id ? $router.push('/profile'): {}}"
              style="background-color: rgba(186, 156, 176)"
              >{{state.currentUser?.id ? 'My Account': "Sign In"}}</a
            >
          </div> -->
          <div
            class="
              space-y-3
              sm:space-y-0
              space-x-0
              sm:space-x-3
              flex flex-col
              sm:flex-row
              px-8
              justify-end
            "
          >
            <div
              v-for="(link, index) in links"
              :key="index"
              :style="{
                backgroundColor: `rgba(186,156,176, ${
                  currentRoute === link.label ? 1 : 0.55
                })`,
              }"
              :class="{
                'menu-item-has-children': link.subLinks && link.subLinks.length,
              }"
            >
              <a @click="$router.push(link.url)" class="text-black">{{
                link.label
              }}</a>
              <!-- <template v-if="link.subLinks && link.subLinks.length">
                                <a href="#" class="menu-expand mdi"></a>
                                <ul class="sub-menu">
                                    <li v-for="(item, subIndex) in link.subLinks" :key="subIndex" class="menu-item"
                                        :class="{ 'menu-item-has-children': item.subLinks && item.subLinks.length }">
                                        <a :href="item.url">{{ item.label }}</a>
                                        <template v-if="item.subLinks && item.subLinks.length">
                                            <a href="#" class="menu-expand mdi"></a>
                                            <ul class="sub-menu">
                                                <li v-for="sItem in item.subLinks" :key="sItem.label" class="menu-item">
                                                    <a :href="sItem.url">{{ sItem.label }}</a>
                                                </li>
                                            </ul>
                                        </template>
                                    </li>
                                </ul>
                            </template> -->
            </div>
          </div>
        </nav>
      </div>

      <div class="md:hidden menu-overlay">
        <DisclosureButton class="border-0"
          ><span class="menu-overlay-before" @click="open = false" />
        </DisclosureButton>
      </div>

      <div class="lg:hidden site-menu-toggle">
        <DisclosureButton class="border-0 rounded-full">
          <a class="mdi mr-0">
            <span class="screen-reader-text">Toggle navigation</span>
          </a>
        </DisclosureButton>
      </div>

      <div class="additional-menu-content">
        <h5>{{ sideMenu.header }}</h5>
        <ul>
          <li v-for="item in sideMenu.links" :key="item.label">
            <a :href="item.url" v-html="item.label" />
          </li>
        </ul>
        <p><img :src="sideMenu.image" alt="" /></p>
        <p v-html="sideMenu.desc" />
      </div>
    </div>
  </Disclosure>
</template>

<script>
import Image from "../../atoms/Image/Image.vue";
import Icons from "../../atoms/Icons/Icons.vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

/**
 * - Use it to show a simple notification
 */
export default {
  components: {
    Image,
    Icons,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  },
  mounted() {
    this.links.push({
      label: this.state.currentUser?.id ? "My Profile": "Sign In",
      url: this.state.currentUser?.id ? "/profile": "/",
      isCurrent: false,
    });
  },
  props: {
    logo: {
      type: Object,
      default: () => null,
    },
    langs: {
      type: Array,
      default: () => [],
    },
    links: {
      type: Array,
      default: () => [],
    },
    sideMenu: {
      type: Object,
      default: () => null,
    },
    currentRoute: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
