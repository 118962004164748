<template>
    <div class="full-content">
        <HeaderSection v-bind="headerProps" />
        <SocialBox v-bind="socialProps" />
        <div class="content-wrap">
            <div class="entry entry-page">
                <div class="entry-content">
                    <div class="gallery-grid-container">
                        <div class="gallery-grid-column column-text">
                            <h2 class="entry-title" v-html="contentInfo.title"></h2>
                            <span v-html="contentInfo.desc"></span>
                        </div>

                        <div class="gallery-grid-column column-image">
                            <a :href="GalleryImg0" data-fancybox="gallery"><img :src="GalleryImg0" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>
                        <div class="gallery-grid-column column-image">
                            <a :href="GalleryImg1" data-fancybox="gallery"><img :src="GalleryImg1" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>
                        <div class="gallery-grid-column column-image full-column"></div>
                        <div class="gallery-grid-column column-image">
                            <a href="https://starlight-media.s3.us-west-2.amazonaws.com/PHOTOSHOOTS/APRIL+2023+-+CHELLSEA+ELLE/Adamari10.png" data-fancybox="gallery"><img :src="'https://starlight-media.s3.us-west-2.amazonaws.com/PHOTOSHOOTS/APRIL+2023+-+CHELLSEA+ELLE/Adamari10.png'" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>
                        <div class="gallery-grid-column column-image">
                            <a href="https://starlight-media.s3.us-west-2.amazonaws.com/PHOTOSHOOTS/APRIL+2023+-+CHELLSEA+ELLE/Adamari1.png" data-fancybox="gallery"><img :src="'https://starlight-media.s3.us-west-2.amazonaws.com/PHOTOSHOOTS/APRIL+2023+-+CHELLSEA+ELLE/Adamari1.png'" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>
                        <div class="gallery-grid-column column-image">
                            <a href="https://starlight-media.s3.us-west-2.amazonaws.com/PHOTOSHOOTS/APRIL+2023+-+CHELLSEA+ELLE/Adamari14.png" data-fancybox="gallery"><img :src="'https://starlight-media.s3.us-west-2.amazonaws.com/PHOTOSHOOTS/APRIL+2023+-+CHELLSEA+ELLE/Adamari14.png'" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>
                        <div class="gallery-grid-column column-image">
                            <a href="https://starlight-media.s3.us-west-2.amazonaws.com/PHOTOSHOOTS/APRIL+2023+-+CHELLSEA+ELLE/Adamari19.png" data-fancybox="gallery"><img :src="'https://starlight-media.s3.us-west-2.amazonaws.com/PHOTOSHOOTS/APRIL+2023+-+CHELLSEA+ELLE/Adamari19.png'" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>
                        <div class="gallery-grid-column column-image full-column">
                            <a :href="GalleryImg12" data-fancybox="gallery"><img :src="GalleryImg12" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>

                        <div class="gallery-grid-column column-image full-column"></div>

                        <div class="gallery-grid-column column-image">
                            <a :href="GalleryImg5" data-fancybox="gallery"><img :src="GalleryImg5" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>
                        <div class="gallery-grid-column column-image">
                            <a :href="GalleryImg6" data-fancybox="gallery"><img :src="GalleryImg6" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>
                        <div class="gallery-grid-column column-image">
                            <a :href="GalleryImg7" data-fancybox="gallery"><img :src="GalleryImg7" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>
                        <div class="gallery-grid-column column-image">
                            <a :href="GalleryImg11" data-fancybox="gallery"><img :src="GalleryImg11" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>

                        <div class="gallery-grid-column column-image full-column"></div>

                        <div class="gallery-grid-column column-image">
                            <a :href="GalleryImg8" data-fancybox="gallery"><img :src="GalleryImg8" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>
                        <div class="gallery-grid-column column-image">
                            <a :href="GalleryImg9" data-fancybox="gallery"><img :src="GalleryImg9" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>
                        <div class="gallery-grid-column column-image">
                            <a :href="GalleryImg10" data-fancybox="gallery"><img :src="GalleryImg10" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>
                        <div class="gallery-grid-column column-image">
                            <a :href="GalleryImg13" data-fancybox="gallery"><img :src="GalleryImg13" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>

                        <div class="gallery-grid-column column-image full-column"></div>

                        <div class="gallery-grid-column column-image">
                            <a :href="GalleryImg2" data-fancybox="gallery"><img :src="GalleryImg2" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>
                        <div class="gallery-grid-column column-image">
                            <a :href="GalleryImg3" data-fancybox="gallery"><img :src="GalleryImg3" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>
                        <div class="gallery-grid-column column-image">
                            <a :href="GalleryImg4" data-fancybox="gallery"><img :src="GalleryImg4" alt="">
                                <span class="mdi mdi-eye"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <CopyRight v-bind="footerProps"/>
        </div>
        </div>
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import SocialBox from "../../molecules/SocialBox/SocialBox.vue";
import { footerProps } from '../../constants';
import GalleryImg0 from "../../../assets/images/gallery_0.jpg";
import GalleryImg1 from "../../../assets/images/gallery_1.jpg";
import GalleryImg2 from "../../../assets/images/gallery_2.jpg";
import GalleryImg3 from "../../../assets/images/gallery_3.jpg";
import GalleryImg4 from "../../../assets/images/gallery_4.jpg";
import GalleryImg5 from "../../../assets/images/gallery_5.jpg";
import GalleryImg6 from "../../../assets/images/gallery_6.jpg";
import GalleryImg7 from "../../../assets/images/gallery_7.jpg";
import GalleryImg8 from "../../../assets/images/gallery_8.jpg";
import GalleryImg9 from "../../../assets/images/gallery_9.jpg";
import GalleryImg10 from "../../../assets/images/gallery_10.jpg";
import GalleryImg11 from "../../../assets/images/gallery_11.jpg";
import GalleryImg12 from "../../../assets/images/gallery_12.jpg";
import GalleryImg13 from "../../../assets/images/gallery_13.jpg";

export default {
  components: {
    HeaderSection, CopyRight, SocialBox
  },
  props: {
    headerProps: {
      type: Object,
      default: () => null
    },
    socialProps: {
      type: Object,
      default: () => null
    },
    contentInfo: {
      type: Object,
      default: () => null
    },
  },
  data() {
    return {
      footerProps,
      GalleryImg0,
      GalleryImg1,
      GalleryImg2,
      GalleryImg3,
      GalleryImg4,
      GalleryImg5,
      GalleryImg6,
      GalleryImg7,
      GalleryImg8,
      GalleryImg9,
      GalleryImg10,
      GalleryImg11,
      GalleryImg12,
      GalleryImg13
    }
  }
}
</script>

<style scoped>

</style>