import LogoImg from '../assets/images/logo.png';
import sideImg from '../assets/images/sample-sidebar.jpg';
import accomodationImg from '../assets/images/sample-accomodation.jpg';
import heroImg from '../assets/images/sample-hero.jpg';
import avatarImg from '../assets/images/sample-avatar.jpg';
import moment from 'moment';

export const avatarSizes = {
    6: 'xs',
    8: 'sm',
    10: 'medium',
    12: 'lg',
    14: 'xl'
}

export const timeOptions = [
    {text: "12:00 AM", value: "12:00 AM"},
    {text: "1:00 AM", value: "1:00 AM"},
    {text: "2:00 AM", value: "2:00 AM"},
    {text: "3:00 AM", value: "3:00 AM"},
    {text: "4:00 AM", value: "4:00 AM"},
    {text: "5:00 AM", value: "5:00 AM"},
    {text: "6:00 AM", value: "6:00 AM"},
    {text: "7:00 AM", value: "7:00 AM"},
    {text: "8:00 AM", value: "8:00 AM"},
    {text: "9:00 AM", value: "9:00 AM"},
    {text: "10:00 AM", value: "10:00 AM"},
    {text: "11:00 AM", value: "11:00 AM"},
    {text: "12:00 PM", value: "12:00 PM"},
    {text: "1:00 PM", value: "1:00 PM"},
    {text: "2:00 PM", value: "2:00 PM"},
    {text: "3:00 PM", value: "3:00 PM"},
    {text: "4:00 PM", value: "4:00 PM"},
    {text: "5:00 PM", value: "5:00 PM"},
    {text: "6:00 PM", value: "6:00 PM"},
    {text: "7:00 PM", value: "7:00 PM"},
    {text: "8:00 PM", value: "8:00 PM"},
    {text: "9:00 PM", value: "9:00 PM"},
    {text: "10:00 PM", value: "10:00 PM"},
    {text: "11:00 PM", value: "11:00 PM"},
]

export const heroImage = heroImg

export const header = {
    languages: [
        {
            name: "En",
            href: "#",
        },
        {
            name: "Fr",
            href: "#",
        },
        {
            name: "De",
            href: "#",
        },
    ],
    navigation: [
        {
            name: "Home",
            href: "#",
        },
        {
            name: " About",
            href: "/#/about",
        },
        {
            name: "Living",
            href: "/#/living",
        },
        {
            name: "Dining",
            href: "/#/dining",
        },
        {
            name: "Features",
            href: "/#/features",
        },
        {
            name: "Contacts",
            href: "/#/contacts",
        },
    ],
}

export const headerProps = {
  logo: {
    light: LogoImg,
    dark: LogoImg,
    url: '/'
  },
  langs: [
    {
      code: 'En',
      isCurrent: true,
    },
    {
      code: 'Fr',
      isCurrent: false,
    },
    {
      code: 'De',
      isCurrent: false,
    },
  ],
  links: [
    {
      label: "Home",
      url: "/",
      isCurrent: true,
    },
    {
      label: "About",
      url: "/about",
      isCurrent: false,
    },
    {
      label: "Pricing",
      url: "/pricing",
      isCurrent: false,
    },
    // {
    //   label: "Amenities",
    //   url: "",
    //   isCurrent: false,
    // },
    {
      label: "Upcoming",
      url: "/upcoming-events",
      isCurrent: false,
    },
    {
      label: "Photos",
      url: "/gallery",
      isCurrent: false,
    },
    {
      label: "Contact",
      url: "/contact",
      isCurrent: false,
    },
  ],
  sideMenu: {
    header: "More goodness:",
    links: [
      {
        label: "Blog Entries",
        url: ""
      },
      {
        label: "Visit the City",
        url: ""
      },
      {
        label: "Our Spa &amp; Pool",
        url: ""
      },
      {
        label: "Privacy Policy",
        url: ""
      },
      {
        label: "Terms &amp; Conditions",
        url: ""
      },
    ],
    image: sideImg,
    desc: "<ins>*This panel is displayed only on large desktop screens.</ins>"
  }
}

export const socialProps = {
    facebook_link: "http://facebook.com",
    twitter_link: "http://twitter.com",
    instagram_link: "http://instagram.com"
}

export const footerProps = {
    year: moment().format('YYYY'),
    siteName: 'Starlight Venues',
    siteLink: "#",
    privacyLink: "#"
}

export const home = {
    slider: [
        {
            title: "The must-see panoramic hotel from France",
            image: "tmp/sample-hero.jpg"
        },
        {
            title: "A Place Where Your Event Shines",
            image: "https://html.liviucerchez.com/leluxe/tmp/sample-video1.jpg",
            mp4Video: "https://html.liviucerchez.com/leluxe/tmp/sample-video1.mp4",
            webmVideo: "https://html.liviucerchez.com/leluxe/tmp/sample-video1.webm"
        },
    ]
}

export const accomodation = {
    list: [
        {
            type: "Single",
            maxPerson: 1,
            isFreeCancellation: true,
            benefit: "Good breakfast included",
            price: 50,
            priceType: "night",
            image: accomodationImg
        },
        {
            type: "Double",
            maxPerson: 2,
            isFreeCancellation: true,
            benefit: "Good breakfast included",
            price: 90,
            priceType: "night",
            image: accomodationImg
        },
        {
            type: "Superior",
            maxPerson: 3,
            isFreeCancellation: true,
            benefit: "Good breakfast included",
            price: 120,
            priceType: "night",
            image: accomodationImg,
            about: [
                'Also known in international jargon as "Junior Suites", here with us these spacious rooms - designed by creatives such as Anna Krikov or Kun Grigorshon - are definitely anything but "junior".',
                'On ca. 40m2, residents of the Starlight Venues are greeted by self-confident yet harmonious color and furniture combinations. For a truly rejuvenating stay in the heart the famous artistic district on the Spittelberg.',
                'Are you in the process of planning your stay at LeLuxe? If so, browse through our packages and specials. You are certain to find something for you.'
            ],
            images: [
                { image: "https://via.placeholder.com/310x465?text=", height: 465, width: 310, title: "Comfortable beds with unique color palette" },
                { image: "https://via.placeholder.com/310x207?text=", height: 207, width: 310, title: "Where retro meets modern" },
                { image: "https://via.placeholder.com/380x580?text=", height: 580, width: 380, title: "Accents here and there, for reinforcing the brand" },
                { image: "https://via.placeholder.com/460x460?text=", height: 460, width: 460, title: "Open spaces with lots of natural light" },
                { image: "https://via.placeholder.com/310x465?text=", height: 465, width: 310, title: "Good design never gets boring" },
                { image: "https://via.placeholder.com/310x207?text=", height: 207, width: 310, title: "Minimal style with great usability" },
            ],
            pages: [{ image: heroImg }, { image: "https://via.placeholder.com/310x465?text=" }, { image: heroImg }]
        },
        {
            type: "President",
            maxPerson: 2,
            isFreeCancellation: true,
            benefit: "Good breakfast included",
            price: 250,
            priceType: "night",
            image: accomodationImg
        },
    ],
    social: [
        { icon: "mdi-facebook", text: "Facebook", link: "https://www.facebook.com/sharer/sharer.php?u=LINK" },
        { icon: "mdi-twitter", text: "Twitter", link: "https://twitter.com/intent/tweet?text=TITLE&amp;url=LINK" },
        { icon: "mdi-google-plus", text: "Google+", link: "https://plus.google.com/share?url=LINK" },
    ]
}

export const accomodationBooking = {
    title: "Book your special event venue",
    alertText: "Make sure you take your time to complete all the fields so we can offer you a quicker and better response.",
    pages: [{ image: heroImg }]
}


export const contact = {
    title: "Get in touch and ask freely",
    content: "We would love to hear from you and help you bring your dream event to life. Our dedicated team is ready to answer any questions you may have and provide you with detailed information about our banquet hall rental services. Whether you're interested in booking a tour, discussing availability and pricing, or seeking personalized event planning assistance, we're just a phone call or email away. Contact us today and let us assist you in creating an unforgettable occasion that exceeds your expectations.",
    address: `1814 W. Jefferson Blvd. <br /> Los Angeles, CA 90018`,
    phones: ["(323) 440-2402"],
    emails: ["sales@starlightvenues.com"]
}

export const blog = {
    title: "Our weekly journal",
    blogList: [
        {
            title: "A day from our designer's life",
            description: "Nori grape silver beet broccoli kombu beet greens fava bean potato quandong celery. Bunya nuts black-eyed pea prairie turnip leek lentil turnip greens parsnip. Sea lettuce lettuce water chestnut eggplant winter purslane fennel",
            date: new Date(),
            totalComments: 3,
            image: "https://via.placeholder.com/1380x550?text="
        },
        {
            title: "The vegetarian way of doing things",
            description: "Gumbo beet greens corn soko endive gumbo gourd. Parsley shallot courgette tatsoi pea sprouts fava bean collard greens dandelion okra wakame tomato. Dandelion cucumber earthnut pea peanut soko zucchini melon azuki bean garlic",
            date: new Date(),
            totalComments: 1,
            image: "https://via.placeholder.com/1380x550?text="
        },
        {
            title: "Bring out the best UI/UX in this",
            description: "Beetroot water spinach okra water chestnut ricebean pea catsear courgette summer purslane water spinach arugula pea tatsoi aubergine spring onion bush tomato kale radicchio turnip chicory salsify pea sprouts fava bean",
            date: new Date("02-04-2023"),
            totalComments: 5,
            image: "https://via.placeholder.com/1380x550?text="
        },
    ]
}

export const singleBlog = {
    title: "Starting fresh one of these days",
    postedAt: "January 1, 2018 at 8:52 pm",
    content: `
    <p>Kombu cauliflower salsify kohlrabi okra sea lettuce broccoli celery lotus root carrot winter purslane turnip greens garlic. Jícama garlic courgette coriander radicchio plantain scallion cauliflower fava bean desert raisin spring onion chicory bunya eggplant bush tomato.</p>
    <p>Beetroot water spinach okra water chestnut ricebean pea catsear courgette summer purslane. Water spinach arugula pea tatsoi aubergine spring onion bush tomato kale radicchio turnip chicory salsify pea sprouts fava bean. Dandelion zucchini burdock yarrow chickpea dandelion sorrel courgette turnip greens tigernut soybean radish artichoke wattle seed endive groundnut broccoli arugula.</p>
    <blockquote>
      <p>Celery swiss chard chicory earthnut potato.</p>
    </blockquote>
    <p>Pea horseradish azuki bean lettuce avocado asparagus okra. Kohlrabi radish okra azuki bean corn fava bean mustard tigernut jícama green bean celtuce collard greens avocado quandong fennel gumbo black-eyed pea. Grape silver beet watercress potato tigernut corn groundnut. Chickweed okra pea winter purslane coriander yarrow sweet pepper radish garlic brussels sprout groundnut summer purslane earthnut pea tomato spring onion azuki bean gourd. Gumbo kakadu plum komatsuna black-eyed pea green bean zucchini gourd winter purslane silver beet rock melon radish asparagus spinach.</p>
    <p>Nori grape silver beet broccoli kombu beet greens fava bean potato quandong celery. Bunya nuts black-eyed pea prairie turnip leek lentil turnip greens parsnip. Sea lettuce lettuce water chestnut eggplant winter purslane fennel azuki bean earthnut pea sierra leone bologi leek soko chicory celtuce parsley jícama salsify.</p>
  `,
    author: {
        img: avatarImg,
        name: "Liviu Cerchez",
        link: "https://liviucerchez.com",
        desc: `<p>Hey I’m Liviu, a freelance Web Developer and Graphics Designer from Europe. You can find me on <a href="http://liviucerchez.com">liviucerchez.com</a>. Follow me on Twitter &amp; Dribbble for any updates.`
    }
}

export const timezones = [
    {
        "name": "Etc/GMT+12",
        "offset": -720,
        "label": "(GMT-12:00) Etc/GMT+12"
    },
    {
        "name": "Etc/GMT+11",
        "offset": -660,
        "label": "(GMT-11:00) Etc/GMT+11"
    },
    {
        "name": "Pacific/Midway",
        "offset": -660,
        "label": "(GMT-11:00) Pacific/Midway"
    },
    {
        "name": "Pacific/Niue",
        "offset": -660,
        "label": "(GMT-11:00) Pacific/Niue"
    },
    {
        "name": "Pacific/Pago_Pago",
        "offset": -660,
        "label": "(GMT-11:00) Pacific/Pago_Pago"
    },
    {
        "name": "Pacific/Samoa",
        "offset": -660,
        "label": "(GMT-11:00) Pacific/Samoa"
    },
    {
        "name": "US/Samoa",
        "offset": -660,
        "label": "(GMT-11:00) US/Samoa"
    },
    {
        "name": "Etc/GMT+10",
        "offset": -600,
        "label": "(GMT-10:00) Etc/GMT+10"
    },
    {
        "name": "HST",
        "offset": -600,
        "label": "(GMT-10:00) HST"
    },
    {
        "name": "Pacific/Honolulu",
        "offset": -600,
        "label": "(GMT-10:00) Pacific/Honolulu"
    },
    {
        "name": "Pacific/Johnston",
        "offset": -600,
        "label": "(GMT-10:00) Pacific/Johnston"
    },
    {
        "name": "Pacific/Rarotonga",
        "offset": -600,
        "label": "(GMT-10:00) Pacific/Rarotonga"
    },
    {
        "name": "Pacific/Tahiti",
        "offset": -600,
        "label": "(GMT-10:00) Pacific/Tahiti"
    },
    {
        "name": "US/Hawaii",
        "offset": -600,
        "label": "(GMT-10:00) US/Hawaii"
    },
    {
        "name": "Pacific/Marquesas",
        "offset": -570,
        "label": "(GMT-09:30) Pacific/Marquesas"
    },
    {
        "name": "America/Adak",
        "offset": -540,
        "label": "(GMT-09:00) America/Adak"
    },
    {
        "name": "America/Atka",
        "offset": -540,
        "label": "(GMT-09:00) America/Atka"
    },
    {
        "name": "Etc/GMT+9",
        "offset": -540,
        "label": "(GMT-09:00) Etc/GMT+9"
    },
    {
        "name": "Pacific/Gambier",
        "offset": -540,
        "label": "(GMT-09:00) Pacific/Gambier"
    },
    {
        "name": "US/Aleutian",
        "offset": -540,
        "label": "(GMT-09:00) US/Aleutian"
    },
    {
        "name": "America/Anchorage",
        "offset": -480,
        "label": "(GMT-08:00) America/Anchorage"
    },
    {
        "name": "America/Juneau",
        "offset": -480,
        "label": "(GMT-08:00) America/Juneau"
    },
    {
        "name": "America/Metlakatla",
        "offset": -480,
        "label": "(GMT-08:00) America/Metlakatla"
    },
    {
        "name": "America/Nome",
        "offset": -480,
        "label": "(GMT-08:00) America/Nome"
    },
    {
        "name": "America/Sitka",
        "offset": -480,
        "label": "(GMT-08:00) America/Sitka"
    },
    {
        "name": "America/Yakutat",
        "offset": -480,
        "label": "(GMT-08:00) America/Yakutat"
    },
    {
        "name": "Etc/GMT+8",
        "offset": -480,
        "label": "(GMT-08:00) Etc/GMT+8"
    },
    {
        "name": "Pacific/Pitcairn",
        "offset": -480,
        "label": "(GMT-08:00) Pacific/Pitcairn"
    },
    {
        "name": "US/Alaska",
        "offset": -480,
        "label": "(GMT-08:00) US/Alaska"
    },
    {
        "name": "America/Creston",
        "offset": -420,
        "label": "(GMT-07:00) America/Creston"
    },
    {
        "name": "America/Dawson",
        "offset": -420,
        "label": "(GMT-07:00) America/Dawson"
    },
    {
        "name": "America/Dawson_Creek",
        "offset": -420,
        "label": "(GMT-07:00) America/Dawson_Creek"
    },
    {
        "name": "America/Ensenada",
        "offset": -420,
        "label": "(GMT-07:00) America/Ensenada"
    },
    {
        "name": "America/Fort_Nelson",
        "offset": -420,
        "label": "(GMT-07:00) America/Fort_Nelson"
    },
    {
        "name": "America/Hermosillo",
        "offset": -420,
        "label": "(GMT-07:00) America/Hermosillo"
    },
    {
        "name": "America/Los_Angeles",
        "offset": -420,
        "label": "(GMT-07:00) America/Los_Angeles"
    },
    {
        "name": "America/Mazatlan",
        "offset": -420,
        "label": "(GMT-07:00) America/Mazatlan"
    },
    {
        "name": "America/Phoenix",
        "offset": -420,
        "label": "(GMT-07:00) America/Phoenix"
    },
    {
        "name": "America/Santa_Isabel",
        "offset": -420,
        "label": "(GMT-07:00) America/Santa_Isabel"
    },
    {
        "name": "America/Tijuana",
        "offset": -420,
        "label": "(GMT-07:00) America/Tijuana"
    },
    {
        "name": "America/Vancouver",
        "offset": -420,
        "label": "(GMT-07:00) America/Vancouver"
    },
    {
        "name": "America/Whitehorse",
        "offset": -420,
        "label": "(GMT-07:00) America/Whitehorse"
    },
    {
        "name": "Canada/Pacific",
        "offset": -420,
        "label": "(GMT-07:00) Canada/Pacific"
    },
    {
        "name": "Canada/Yukon",
        "offset": -420,
        "label": "(GMT-07:00) Canada/Yukon"
    },
    {
        "name": "Etc/GMT+7",
        "offset": -420,
        "label": "(GMT-07:00) Etc/GMT+7"
    },
    {
        "name": "MST",
        "offset": -420,
        "label": "(GMT-07:00) MST"
    },
    {
        "name": "Mexico/BajaNorte",
        "offset": -420,
        "label": "(GMT-07:00) Mexico/BajaNorte"
    },
    {
        "name": "Mexico/BajaSur",
        "offset": -420,
        "label": "(GMT-07:00) Mexico/BajaSur"
    },
    {
        "name": "PST8PDT",
        "offset": -420,
        "label": "(GMT-07:00) PST8PDT"
    },
    {
        "name": "US/Arizona",
        "offset": -420,
        "label": "(GMT-07:00) US/Arizona"
    },
    {
        "name": "US/Pacific",
        "offset": -420,
        "label": "(GMT-07:00) US/Pacific"
    },
    {
        "name": "America/Bahia_Banderas",
        "offset": -360,
        "label": "(GMT-06:00) America/Bahia_Banderas"
    },
    {
        "name": "America/Belize",
        "offset": -360,
        "label": "(GMT-06:00) America/Belize"
    },
    {
        "name": "America/Boise",
        "offset": -360,
        "label": "(GMT-06:00) America/Boise"
    },
    {
        "name": "America/Cambridge_Bay",
        "offset": -360,
        "label": "(GMT-06:00) America/Cambridge_Bay"
    },
    {
        "name": "America/Chihuahua",
        "offset": -360,
        "label": "(GMT-06:00) America/Chihuahua"
    },
    {
        "name": "America/Ciudad_Juarez",
        "offset": -360,
        "label": "(GMT-06:00) America/Ciudad_Juarez"
    },
    {
        "name": "America/Costa_Rica",
        "offset": -360,
        "label": "(GMT-06:00) America/Costa_Rica"
    },
    {
        "name": "America/Denver",
        "offset": -360,
        "label": "(GMT-06:00) America/Denver"
    },
    {
        "name": "America/Edmonton",
        "offset": -360,
        "label": "(GMT-06:00) America/Edmonton"
    },
    {
        "name": "America/El_Salvador",
        "offset": -360,
        "label": "(GMT-06:00) America/El_Salvador"
    },
    {
        "name": "America/Guatemala",
        "offset": -360,
        "label": "(GMT-06:00) America/Guatemala"
    },
    {
        "name": "America/Inuvik",
        "offset": -360,
        "label": "(GMT-06:00) America/Inuvik"
    },
    {
        "name": "America/Managua",
        "offset": -360,
        "label": "(GMT-06:00) America/Managua"
    },
    {
        "name": "America/Merida",
        "offset": -360,
        "label": "(GMT-06:00) America/Merida"
    },
    {
        "name": "America/Mexico_City",
        "offset": -360,
        "label": "(GMT-06:00) America/Mexico_City"
    },
    {
        "name": "America/Monterrey",
        "offset": -360,
        "label": "(GMT-06:00) America/Monterrey"
    },
    {
        "name": "America/Regina",
        "offset": -360,
        "label": "(GMT-06:00) America/Regina"
    },
    {
        "name": "America/Shiprock",
        "offset": -360,
        "label": "(GMT-06:00) America/Shiprock"
    },
    {
        "name": "America/Swift_Current",
        "offset": -360,
        "label": "(GMT-06:00) America/Swift_Current"
    },
    {
        "name": "America/Tegucigalpa",
        "offset": -360,
        "label": "(GMT-06:00) America/Tegucigalpa"
    },
    {
        "name": "America/Yellowknife",
        "offset": -360,
        "label": "(GMT-06:00) America/Yellowknife"
    },
    {
        "name": "Canada/Mountain",
        "offset": -360,
        "label": "(GMT-06:00) Canada/Mountain"
    },
    {
        "name": "Canada/Saskatchewan",
        "offset": -360,
        "label": "(GMT-06:00) Canada/Saskatchewan"
    },
    {
        "name": "Chile/EasterIsland",
        "offset": -360,
        "label": "(GMT-06:00) Chile/EasterIsland"
    },
    {
        "name": "Etc/GMT+6",
        "offset": -360,
        "label": "(GMT-06:00) Etc/GMT+6"
    },
    {
        "name": "MST7MDT",
        "offset": -360,
        "label": "(GMT-06:00) MST7MDT"
    },
    {
        "name": "Mexico/General",
        "offset": -360,
        "label": "(GMT-06:00) Mexico/General"
    },
    {
        "name": "Navajo",
        "offset": -360,
        "label": "(GMT-06:00) Navajo"
    },
    {
        "name": "Pacific/Easter",
        "offset": -360,
        "label": "(GMT-06:00) Pacific/Easter"
    },
    {
        "name": "Pacific/Galapagos",
        "offset": -360,
        "label": "(GMT-06:00) Pacific/Galapagos"
    },
    {
        "name": "US/Mountain",
        "offset": -360,
        "label": "(GMT-06:00) US/Mountain"
    },
    {
        "name": "America/Atikokan",
        "offset": -300,
        "label": "(GMT-05:00) America/Atikokan"
    },
    {
        "name": "America/Bogota",
        "offset": -300,
        "label": "(GMT-05:00) America/Bogota"
    },
    {
        "name": "America/Cancun",
        "offset": -300,
        "label": "(GMT-05:00) America/Cancun"
    },
    {
        "name": "America/Cayman",
        "offset": -300,
        "label": "(GMT-05:00) America/Cayman"
    },
    {
        "name": "America/Chicago",
        "offset": -300,
        "label": "(GMT-05:00) America/Chicago"
    },
    {
        "name": "America/Coral_Harbour",
        "offset": -300,
        "label": "(GMT-05:00) America/Coral_Harbour"
    },
    {
        "name": "America/Eirunepe",
        "offset": -300,
        "label": "(GMT-05:00) America/Eirunepe"
    },
    {
        "name": "America/Guayaquil",
        "offset": -300,
        "label": "(GMT-05:00) America/Guayaquil"
    },
    {
        "name": "America/Indiana/Knox",
        "offset": -300,
        "label": "(GMT-05:00) America/Indiana/Knox"
    },
    {
        "name": "America/Indiana/Tell_City",
        "offset": -300,
        "label": "(GMT-05:00) America/Indiana/Tell_City"
    },
    {
        "name": "America/Jamaica",
        "offset": -300,
        "label": "(GMT-05:00) America/Jamaica"
    },
    {
        "name": "America/Knox_IN",
        "offset": -300,
        "label": "(GMT-05:00) America/Knox_IN"
    },
    {
        "name": "America/Lima",
        "offset": -300,
        "label": "(GMT-05:00) America/Lima"
    },
    {
        "name": "America/Matamoros",
        "offset": -300,
        "label": "(GMT-05:00) America/Matamoros"
    },
    {
        "name": "America/Menominee",
        "offset": -300,
        "label": "(GMT-05:00) America/Menominee"
    },
    {
        "name": "America/North_Dakota/Beulah",
        "offset": -300,
        "label": "(GMT-05:00) America/North_Dakota/Beulah"
    },
    {
        "name": "America/North_Dakota/Center",
        "offset": -300,
        "label": "(GMT-05:00) America/North_Dakota/Center"
    },
    {
        "name": "America/North_Dakota/New_Salem",
        "offset": -300,
        "label": "(GMT-05:00) America/North_Dakota/New_Salem"
    },
    {
        "name": "America/Ojinaga",
        "offset": -300,
        "label": "(GMT-05:00) America/Ojinaga"
    },
    {
        "name": "America/Panama",
        "offset": -300,
        "label": "(GMT-05:00) America/Panama"
    },
    {
        "name": "America/Porto_Acre",
        "offset": -300,
        "label": "(GMT-05:00) America/Porto_Acre"
    },
    {
        "name": "America/Rainy_River",
        "offset": -300,
        "label": "(GMT-05:00) America/Rainy_River"
    },
    {
        "name": "America/Rankin_Inlet",
        "offset": -300,
        "label": "(GMT-05:00) America/Rankin_Inlet"
    },
    {
        "name": "America/Resolute",
        "offset": -300,
        "label": "(GMT-05:00) America/Resolute"
    },
    {
        "name": "America/Rio_Branco",
        "offset": -300,
        "label": "(GMT-05:00) America/Rio_Branco"
    },
    {
        "name": "America/Winnipeg",
        "offset": -300,
        "label": "(GMT-05:00) America/Winnipeg"
    },
    {
        "name": "Brazil/Acre",
        "offset": -300,
        "label": "(GMT-05:00) Brazil/Acre"
    },
    {
        "name": "CST6CDT",
        "offset": -300,
        "label": "(GMT-05:00) CST6CDT"
    },
    {
        "name": "Canada/Central",
        "offset": -300,
        "label": "(GMT-05:00) Canada/Central"
    },
    {
        "name": "EST",
        "offset": -300,
        "label": "(GMT-05:00) EST"
    },
    {
        "name": "Etc/GMT+5",
        "offset": -300,
        "label": "(GMT-05:00) Etc/GMT+5"
    },
    {
        "name": "Jamaica",
        "offset": -300,
        "label": "(GMT-05:00) Jamaica"
    },
    {
        "name": "US/Central",
        "offset": -300,
        "label": "(GMT-05:00) US/Central"
    },
    {
        "name": "US/Indiana-Starke",
        "offset": -300,
        "label": "(GMT-05:00) US/Indiana-Starke"
    },
    {
        "name": "America/Anguilla",
        "offset": -240,
        "label": "(GMT-04:00) America/Anguilla"
    },
    {
        "name": "America/Antigua",
        "offset": -240,
        "label": "(GMT-04:00) America/Antigua"
    },
    {
        "name": "America/Aruba",
        "offset": -240,
        "label": "(GMT-04:00) America/Aruba"
    },
    {
        "name": "America/Asuncion",
        "offset": -240,
        "label": "(GMT-04:00) America/Asuncion"
    },
    {
        "name": "America/Barbados",
        "offset": -240,
        "label": "(GMT-04:00) America/Barbados"
    },
    {
        "name": "America/Blanc-Sablon",
        "offset": -240,
        "label": "(GMT-04:00) America/Blanc-Sablon"
    },
    {
        "name": "America/Boa_Vista",
        "offset": -240,
        "label": "(GMT-04:00) America/Boa_Vista"
    },
    {
        "name": "America/Campo_Grande",
        "offset": -240,
        "label": "(GMT-04:00) America/Campo_Grande"
    },
    {
        "name": "America/Caracas",
        "offset": -240,
        "label": "(GMT-04:00) America/Caracas"
    },
    {
        "name": "America/Cuiaba",
        "offset": -240,
        "label": "(GMT-04:00) America/Cuiaba"
    },
    {
        "name": "America/Curacao",
        "offset": -240,
        "label": "(GMT-04:00) America/Curacao"
    },
    {
        "name": "America/Detroit",
        "offset": -240,
        "label": "(GMT-04:00) America/Detroit"
    },
    {
        "name": "America/Dominica",
        "offset": -240,
        "label": "(GMT-04:00) America/Dominica"
    },
    {
        "name": "America/Fort_Wayne",
        "offset": -240,
        "label": "(GMT-04:00) America/Fort_Wayne"
    },
    {
        "name": "America/Grand_Turk",
        "offset": -240,
        "label": "(GMT-04:00) America/Grand_Turk"
    },
    {
        "name": "America/Grenada",
        "offset": -240,
        "label": "(GMT-04:00) America/Grenada"
    },
    {
        "name": "America/Guadeloupe",
        "offset": -240,
        "label": "(GMT-04:00) America/Guadeloupe"
    },
    {
        "name": "America/Guyana",
        "offset": -240,
        "label": "(GMT-04:00) America/Guyana"
    },
    {
        "name": "America/Havana",
        "offset": -240,
        "label": "(GMT-04:00) America/Havana"
    },
    {
        "name": "America/Indiana/Indianapolis",
        "offset": -240,
        "label": "(GMT-04:00) America/Indiana/Indianapolis"
    },
    {
        "name": "America/Indiana/Marengo",
        "offset": -240,
        "label": "(GMT-04:00) America/Indiana/Marengo"
    },
    {
        "name": "America/Indiana/Petersburg",
        "offset": -240,
        "label": "(GMT-04:00) America/Indiana/Petersburg"
    },
    {
        "name": "America/Indiana/Vevay",
        "offset": -240,
        "label": "(GMT-04:00) America/Indiana/Vevay"
    },
    {
        "name": "America/Indiana/Vincennes",
        "offset": -240,
        "label": "(GMT-04:00) America/Indiana/Vincennes"
    },
    {
        "name": "America/Indiana/Winamac",
        "offset": -240,
        "label": "(GMT-04:00) America/Indiana/Winamac"
    },
    {
        "name": "America/Indianapolis",
        "offset": -240,
        "label": "(GMT-04:00) America/Indianapolis"
    },
    {
        "name": "America/Iqaluit",
        "offset": -240,
        "label": "(GMT-04:00) America/Iqaluit"
    },
    {
        "name": "America/Kentucky/Louisville",
        "offset": -240,
        "label": "(GMT-04:00) America/Kentucky/Louisville"
    },
    {
        "name": "America/Kentucky/Monticello",
        "offset": -240,
        "label": "(GMT-04:00) America/Kentucky/Monticello"
    },
    {
        "name": "America/Kralendijk",
        "offset": -240,
        "label": "(GMT-04:00) America/Kralendijk"
    },
    {
        "name": "America/La_Paz",
        "offset": -240,
        "label": "(GMT-04:00) America/La_Paz"
    },
    {
        "name": "America/Louisville",
        "offset": -240,
        "label": "(GMT-04:00) America/Louisville"
    },
    {
        "name": "America/Lower_Princes",
        "offset": -240,
        "label": "(GMT-04:00) America/Lower_Princes"
    },
    {
        "name": "America/Manaus",
        "offset": -240,
        "label": "(GMT-04:00) America/Manaus"
    },
    {
        "name": "America/Marigot",
        "offset": -240,
        "label": "(GMT-04:00) America/Marigot"
    },
    {
        "name": "America/Martinique",
        "offset": -240,
        "label": "(GMT-04:00) America/Martinique"
    },
    {
        "name": "America/Montreal",
        "offset": -240,
        "label": "(GMT-04:00) America/Montreal"
    },
    {
        "name": "America/Montserrat",
        "offset": -240,
        "label": "(GMT-04:00) America/Montserrat"
    },
    {
        "name": "America/Nassau",
        "offset": -240,
        "label": "(GMT-04:00) America/Nassau"
    },
    {
        "name": "America/New_York",
        "offset": -240,
        "label": "(GMT-04:00) America/New_York"
    },
    {
        "name": "America/Nipigon",
        "offset": -240,
        "label": "(GMT-04:00) America/Nipigon"
    },
    {
        "name": "America/Pangnirtung",
        "offset": -240,
        "label": "(GMT-04:00) America/Pangnirtung"
    },
    {
        "name": "America/Port-au-Prince",
        "offset": -240,
        "label": "(GMT-04:00) America/Port-au-Prince"
    },
    {
        "name": "America/Port_of_Spain",
        "offset": -240,
        "label": "(GMT-04:00) America/Port_of_Spain"
    },
    {
        "name": "America/Porto_Velho",
        "offset": -240,
        "label": "(GMT-04:00) America/Porto_Velho"
    },
    {
        "name": "America/Puerto_Rico",
        "offset": -240,
        "label": "(GMT-04:00) America/Puerto_Rico"
    },
    {
        "name": "America/Santiago",
        "offset": -240,
        "label": "(GMT-04:00) America/Santiago"
    },
    {
        "name": "America/Santo_Domingo",
        "offset": -240,
        "label": "(GMT-04:00) America/Santo_Domingo"
    },
    {
        "name": "America/St_Barthelemy",
        "offset": -240,
        "label": "(GMT-04:00) America/St_Barthelemy"
    },
    {
        "name": "America/St_Kitts",
        "offset": -240,
        "label": "(GMT-04:00) America/St_Kitts"
    },
    {
        "name": "America/St_Lucia",
        "offset": -240,
        "label": "(GMT-04:00) America/St_Lucia"
    },
    {
        "name": "America/St_Thomas",
        "offset": -240,
        "label": "(GMT-04:00) America/St_Thomas"
    },
    {
        "name": "America/St_Vincent",
        "offset": -240,
        "label": "(GMT-04:00) America/St_Vincent"
    },
    {
        "name": "America/Thunder_Bay",
        "offset": -240,
        "label": "(GMT-04:00) America/Thunder_Bay"
    },
    {
        "name": "America/Toronto",
        "offset": -240,
        "label": "(GMT-04:00) America/Toronto"
    },
    {
        "name": "America/Tortola",
        "offset": -240,
        "label": "(GMT-04:00) America/Tortola"
    },
    {
        "name": "America/Virgin",
        "offset": -240,
        "label": "(GMT-04:00) America/Virgin"
    },
    {
        "name": "Brazil/West",
        "offset": -240,
        "label": "(GMT-04:00) Brazil/West"
    },
    {
        "name": "Canada/Eastern",
        "offset": -240,
        "label": "(GMT-04:00) Canada/Eastern"
    },
    {
        "name": "Chile/Continental",
        "offset": -240,
        "label": "(GMT-04:00) Chile/Continental"
    },
    {
        "name": "Cuba",
        "offset": -240,
        "label": "(GMT-04:00) Cuba"
    },
    {
        "name": "EST5EDT",
        "offset": -240,
        "label": "(GMT-04:00) EST5EDT"
    },
    {
        "name": "Etc/GMT+4",
        "offset": -240,
        "label": "(GMT-04:00) Etc/GMT+4"
    },
    {
        "name": "US/East-Indiana",
        "offset": -240,
        "label": "(GMT-04:00) US/East-Indiana"
    },
    {
        "name": "US/Eastern",
        "offset": -240,
        "label": "(GMT-04:00) US/Eastern"
    },
    {
        "name": "US/Michigan",
        "offset": -240,
        "label": "(GMT-04:00) US/Michigan"
    },
    {
        "name": "America/Araguaina",
        "offset": -180,
        "label": "(GMT-03:00) America/Araguaina"
    },
    {
        "name": "America/Argentina/Buenos_Aires",
        "offset": -180,
        "label": "(GMT-03:00) America/Argentina/Buenos_Aires"
    },
    {
        "name": "America/Argentina/Catamarca",
        "offset": -180,
        "label": "(GMT-03:00) America/Argentina/Catamarca"
    },
    {
        "name": "America/Argentina/ComodRivadavia",
        "offset": -180,
        "label": "(GMT-03:00) America/Argentina/ComodRivadavia"
    },
    {
        "name": "America/Argentina/Cordoba",
        "offset": -180,
        "label": "(GMT-03:00) America/Argentina/Cordoba"
    },
    {
        "name": "America/Argentina/Jujuy",
        "offset": -180,
        "label": "(GMT-03:00) America/Argentina/Jujuy"
    },
    {
        "name": "America/Argentina/La_Rioja",
        "offset": -180,
        "label": "(GMT-03:00) America/Argentina/La_Rioja"
    },
    {
        "name": "America/Argentina/Mendoza",
        "offset": -180,
        "label": "(GMT-03:00) America/Argentina/Mendoza"
    },
    {
        "name": "America/Argentina/Rio_Gallegos",
        "offset": -180,
        "label": "(GMT-03:00) America/Argentina/Rio_Gallegos"
    },
    {
        "name": "America/Argentina/Salta",
        "offset": -180,
        "label": "(GMT-03:00) America/Argentina/Salta"
    },
    {
        "name": "America/Argentina/San_Juan",
        "offset": -180,
        "label": "(GMT-03:00) America/Argentina/San_Juan"
    },
    {
        "name": "America/Argentina/San_Luis",
        "offset": -180,
        "label": "(GMT-03:00) America/Argentina/San_Luis"
    },
    {
        "name": "America/Argentina/Tucuman",
        "offset": -180,
        "label": "(GMT-03:00) America/Argentina/Tucuman"
    },
    {
        "name": "America/Argentina/Ushuaia",
        "offset": -180,
        "label": "(GMT-03:00) America/Argentina/Ushuaia"
    },
    {
        "name": "America/Bahia",
        "offset": -180,
        "label": "(GMT-03:00) America/Bahia"
    },
    {
        "name": "America/Belem",
        "offset": -180,
        "label": "(GMT-03:00) America/Belem"
    },
    {
        "name": "America/Buenos_Aires",
        "offset": -180,
        "label": "(GMT-03:00) America/Buenos_Aires"
    },
    {
        "name": "America/Catamarca",
        "offset": -180,
        "label": "(GMT-03:00) America/Catamarca"
    },
    {
        "name": "America/Cayenne",
        "offset": -180,
        "label": "(GMT-03:00) America/Cayenne"
    },
    {
        "name": "America/Cordoba",
        "offset": -180,
        "label": "(GMT-03:00) America/Cordoba"
    },
    {
        "name": "America/Fortaleza",
        "offset": -180,
        "label": "(GMT-03:00) America/Fortaleza"
    },
    {
        "name": "America/Glace_Bay",
        "offset": -180,
        "label": "(GMT-03:00) America/Glace_Bay"
    },
    {
        "name": "America/Goose_Bay",
        "offset": -180,
        "label": "(GMT-03:00) America/Goose_Bay"
    },
    {
        "name": "America/Halifax",
        "offset": -180,
        "label": "(GMT-03:00) America/Halifax"
    },
    {
        "name": "America/Jujuy",
        "offset": -180,
        "label": "(GMT-03:00) America/Jujuy"
    },
    {
        "name": "America/Maceio",
        "offset": -180,
        "label": "(GMT-03:00) America/Maceio"
    },
    {
        "name": "America/Mendoza",
        "offset": -180,
        "label": "(GMT-03:00) America/Mendoza"
    },
    {
        "name": "America/Moncton",
        "offset": -180,
        "label": "(GMT-03:00) America/Moncton"
    },
    {
        "name": "America/Montevideo",
        "offset": -180,
        "label": "(GMT-03:00) America/Montevideo"
    },
    {
        "name": "America/Paramaribo",
        "offset": -180,
        "label": "(GMT-03:00) America/Paramaribo"
    },
    {
        "name": "America/Punta_Arenas",
        "offset": -180,
        "label": "(GMT-03:00) America/Punta_Arenas"
    },
    {
        "name": "America/Recife",
        "offset": -180,
        "label": "(GMT-03:00) America/Recife"
    },
    {
        "name": "America/Rosario",
        "offset": -180,
        "label": "(GMT-03:00) America/Rosario"
    },
    {
        "name": "America/Santarem",
        "offset": -180,
        "label": "(GMT-03:00) America/Santarem"
    },
    {
        "name": "America/Sao_Paulo",
        "offset": -180,
        "label": "(GMT-03:00) America/Sao_Paulo"
    },
    {
        "name": "America/Thule",
        "offset": -180,
        "label": "(GMT-03:00) America/Thule"
    },
    {
        "name": "Antarctica/Palmer",
        "offset": -180,
        "label": "(GMT-03:00) Antarctica/Palmer"
    },
    {
        "name": "Antarctica/Rothera",
        "offset": -180,
        "label": "(GMT-03:00) Antarctica/Rothera"
    },
    {
        "name": "Atlantic/Bermuda",
        "offset": -180,
        "label": "(GMT-03:00) Atlantic/Bermuda"
    },
    {
        "name": "Atlantic/Stanley",
        "offset": -180,
        "label": "(GMT-03:00) Atlantic/Stanley"
    },
    {
        "name": "Brazil/East",
        "offset": -180,
        "label": "(GMT-03:00) Brazil/East"
    },
    {
        "name": "Canada/Atlantic",
        "offset": -180,
        "label": "(GMT-03:00) Canada/Atlantic"
    },
    {
        "name": "Etc/GMT+3",
        "offset": -180,
        "label": "(GMT-03:00) Etc/GMT+3"
    },
    {
        "name": "America/St_Johns",
        "offset": -150,
        "label": "(GMT-02:30) America/St_Johns"
    },
    {
        "name": "Canada/Newfoundland",
        "offset": -150,
        "label": "(GMT-02:30) Canada/Newfoundland"
    },
    {
        "name": "America/Godthab",
        "offset": -120,
        "label": "(GMT-02:00) America/Godthab"
    },
    {
        "name": "America/Miquelon",
        "offset": -120,
        "label": "(GMT-02:00) America/Miquelon"
    },
    {
        "name": "America/Noronha",
        "offset": -120,
        "label": "(GMT-02:00) America/Noronha"
    },
    {
        "name": "America/Nuuk",
        "offset": -120,
        "label": "(GMT-02:00) America/Nuuk"
    },
    {
        "name": "Atlantic/South_Georgia",
        "offset": -120,
        "label": "(GMT-02:00) Atlantic/South_Georgia"
    },
    {
        "name": "Brazil/DeNoronha",
        "offset": -120,
        "label": "(GMT-02:00) Brazil/DeNoronha"
    },
    {
        "name": "Etc/GMT+2",
        "offset": -120,
        "label": "(GMT-02:00) Etc/GMT+2"
    },
    {
        "name": "Atlantic/Cape_Verde",
        "offset": -60,
        "label": "(GMT-01:00) Atlantic/Cape_Verde"
    },
    {
        "name": "Etc/GMT+1",
        "offset": -60,
        "label": "(GMT-01:00) Etc/GMT+1"
    },
    {
        "name": "Africa/Abidjan",
        "offset": 0,
        "label": "(GMT) Africa/Abidjan"
    },
    {
        "name": "Africa/Accra",
        "offset": 0,
        "label": "(GMT) Africa/Accra"
    },
    {
        "name": "Africa/Bamako",
        "offset": 0,
        "label": "(GMT) Africa/Bamako"
    },
    {
        "name": "Africa/Banjul",
        "offset": 0,
        "label": "(GMT) Africa/Banjul"
    },
    {
        "name": "Africa/Bissau",
        "offset": 0,
        "label": "(GMT) Africa/Bissau"
    },
    {
        "name": "Africa/Conakry",
        "offset": 0,
        "label": "(GMT) Africa/Conakry"
    },
    {
        "name": "Africa/Dakar",
        "offset": 0,
        "label": "(GMT) Africa/Dakar"
    },
    {
        "name": "Africa/Freetown",
        "offset": 0,
        "label": "(GMT) Africa/Freetown"
    },
    {
        "name": "Africa/Lome",
        "offset": 0,
        "label": "(GMT) Africa/Lome"
    },
    {
        "name": "Africa/Monrovia",
        "offset": 0,
        "label": "(GMT) Africa/Monrovia"
    },
    {
        "name": "Africa/Nouakchott",
        "offset": 0,
        "label": "(GMT) Africa/Nouakchott"
    },
    {
        "name": "Africa/Ouagadougou",
        "offset": 0,
        "label": "(GMT) Africa/Ouagadougou"
    },
    {
        "name": "Africa/Sao_Tome",
        "offset": 0,
        "label": "(GMT) Africa/Sao_Tome"
    },
    {
        "name": "Africa/Timbuktu",
        "offset": 0,
        "label": "(GMT) Africa/Timbuktu"
    },
    {
        "name": "America/Danmarkshavn",
        "offset": 0,
        "label": "(GMT) America/Danmarkshavn"
    },
    {
        "name": "America/Scoresbysund",
        "offset": 0,
        "label": "(GMT) America/Scoresbysund"
    },
    {
        "name": "Atlantic/Azores",
        "offset": 0,
        "label": "(GMT) Atlantic/Azores"
    },
    {
        "name": "Atlantic/Reykjavik",
        "offset": 0,
        "label": "(GMT) Atlantic/Reykjavik"
    },
    {
        "name": "Atlantic/St_Helena",
        "offset": 0,
        "label": "(GMT) Atlantic/St_Helena"
    },
    {
        "name": "Etc/GMT",
        "offset": 0,
        "label": "(GMT) Etc/GMT"
    },
    {
        "name": "Etc/GMT+0",
        "offset": 0,
        "label": "(GMT) Etc/GMT+0"
    },
    {
        "name": "Etc/GMT-0",
        "offset": 0,
        "label": "(GMT) Etc/GMT-0"
    },
    {
        "name": "Etc/GMT0",
        "offset": 0,
        "label": "(GMT) Etc/GMT0"
    },
    {
        "name": "Etc/Greenwich",
        "offset": 0,
        "label": "(GMT) Etc/Greenwich"
    },
    {
        "name": "Etc/UCT",
        "offset": 0,
        "label": "(GMT) Etc/UCT"
    },
    {
        "name": "Etc/UTC",
        "offset": 0,
        "label": "(GMT) Etc/UTC"
    },
    {
        "name": "Etc/Universal",
        "offset": 0,
        "label": "(GMT) Etc/Universal"
    },
    {
        "name": "Etc/Zulu",
        "offset": 0,
        "label": "(GMT) Etc/Zulu"
    },
    {
        "name": "GMT",
        "offset": 0,
        "label": "(GMT) GMT"
    },
    {
        "name": "GMT+0",
        "offset": 0,
        "label": "(GMT) GMT+0"
    },
    {
        "name": "GMT-0",
        "offset": 0,
        "label": "(GMT) GMT-0"
    },
    {
        "name": "GMT0",
        "offset": 0,
        "label": "(GMT) GMT0"
    },
    {
        "name": "Greenwich",
        "offset": 0,
        "label": "(GMT) Greenwich"
    },
    {
        "name": "Iceland",
        "offset": 0,
        "label": "(GMT) Iceland"
    },
    {
        "name": "UCT",
        "offset": 0,
        "label": "(GMT) UCT"
    },
    {
        "name": "UTC",
        "offset": 0,
        "label": "(GMT) UTC"
    },
    {
        "name": "Universal",
        "offset": 0,
        "label": "(GMT) Universal"
    },
    {
        "name": "Zulu",
        "offset": 0,
        "label": "(GMT) Zulu"
    },
    {
        "name": "Africa/Algiers",
        "offset": 60,
        "label": "(GMT+01:00) Africa/Algiers"
    },
    {
        "name": "Africa/Bangui",
        "offset": 60,
        "label": "(GMT+01:00) Africa/Bangui"
    },
    {
        "name": "Africa/Brazzaville",
        "offset": 60,
        "label": "(GMT+01:00) Africa/Brazzaville"
    },
    {
        "name": "Africa/Casablanca",
        "offset": 60,
        "label": "(GMT+01:00) Africa/Casablanca"
    },
    {
        "name": "Africa/Douala",
        "offset": 60,
        "label": "(GMT+01:00) Africa/Douala"
    },
    {
        "name": "Africa/El_Aaiun",
        "offset": 60,
        "label": "(GMT+01:00) Africa/El_Aaiun"
    },
    {
        "name": "Africa/Kinshasa",
        "offset": 60,
        "label": "(GMT+01:00) Africa/Kinshasa"
    },
    {
        "name": "Africa/Lagos",
        "offset": 60,
        "label": "(GMT+01:00) Africa/Lagos"
    },
    {
        "name": "Africa/Libreville",
        "offset": 60,
        "label": "(GMT+01:00) Africa/Libreville"
    },
    {
        "name": "Africa/Luanda",
        "offset": 60,
        "label": "(GMT+01:00) Africa/Luanda"
    },
    {
        "name": "Africa/Malabo",
        "offset": 60,
        "label": "(GMT+01:00) Africa/Malabo"
    },
    {
        "name": "Africa/Ndjamena",
        "offset": 60,
        "label": "(GMT+01:00) Africa/Ndjamena"
    },
    {
        "name": "Africa/Niamey",
        "offset": 60,
        "label": "(GMT+01:00) Africa/Niamey"
    },
    {
        "name": "Africa/Porto-Novo",
        "offset": 60,
        "label": "(GMT+01:00) Africa/Porto-Novo"
    },
    {
        "name": "Africa/Tunis",
        "offset": 60,
        "label": "(GMT+01:00) Africa/Tunis"
    },
    {
        "name": "Atlantic/Canary",
        "offset": 60,
        "label": "(GMT+01:00) Atlantic/Canary"
    },
    {
        "name": "Atlantic/Faeroe",
        "offset": 60,
        "label": "(GMT+01:00) Atlantic/Faeroe"
    },
    {
        "name": "Atlantic/Faroe",
        "offset": 60,
        "label": "(GMT+01:00) Atlantic/Faroe"
    },
    {
        "name": "Atlantic/Madeira",
        "offset": 60,
        "label": "(GMT+01:00) Atlantic/Madeira"
    },
    {
        "name": "Eire",
        "offset": 60,
        "label": "(GMT+01:00) Eire"
    },
    {
        "name": "Etc/GMT-1",
        "offset": 60,
        "label": "(GMT+01:00) Etc/GMT-1"
    },
    {
        "name": "Europe/Belfast",
        "offset": 60,
        "label": "(GMT+01:00) Europe/Belfast"
    },
    {
        "name": "Europe/Dublin",
        "offset": 60,
        "label": "(GMT+01:00) Europe/Dublin"
    },
    {
        "name": "Europe/Guernsey",
        "offset": 60,
        "label": "(GMT+01:00) Europe/Guernsey"
    },
    {
        "name": "Europe/Isle_of_Man",
        "offset": 60,
        "label": "(GMT+01:00) Europe/Isle_of_Man"
    },
    {
        "name": "Europe/Jersey",
        "offset": 60,
        "label": "(GMT+01:00) Europe/Jersey"
    },
    {
        "name": "Europe/Lisbon",
        "offset": 60,
        "label": "(GMT+01:00) Europe/Lisbon"
    },
    {
        "name": "Europe/London",
        "offset": 60,
        "label": "(GMT+01:00) Europe/London"
    },
    {
        "name": "GB",
        "offset": 60,
        "label": "(GMT+01:00) GB"
    },
    {
        "name": "GB-Eire",
        "offset": 60,
        "label": "(GMT+01:00) GB-Eire"
    },
    {
        "name": "Portugal",
        "offset": 60,
        "label": "(GMT+01:00) Portugal"
    },
    {
        "name": "WET",
        "offset": 60,
        "label": "(GMT+01:00) WET"
    },
    {
        "name": "Africa/Blantyre",
        "offset": 120,
        "label": "(GMT+02:00) Africa/Blantyre"
    },
    {
        "name": "Africa/Bujumbura",
        "offset": 120,
        "label": "(GMT+02:00) Africa/Bujumbura"
    },
    {
        "name": "Africa/Ceuta",
        "offset": 120,
        "label": "(GMT+02:00) Africa/Ceuta"
    },
    {
        "name": "Africa/Gaborone",
        "offset": 120,
        "label": "(GMT+02:00) Africa/Gaborone"
    },
    {
        "name": "Africa/Harare",
        "offset": 120,
        "label": "(GMT+02:00) Africa/Harare"
    },
    {
        "name": "Africa/Johannesburg",
        "offset": 120,
        "label": "(GMT+02:00) Africa/Johannesburg"
    },
    {
        "name": "Africa/Juba",
        "offset": 120,
        "label": "(GMT+02:00) Africa/Juba"
    },
    {
        "name": "Africa/Khartoum",
        "offset": 120,
        "label": "(GMT+02:00) Africa/Khartoum"
    },
    {
        "name": "Africa/Kigali",
        "offset": 120,
        "label": "(GMT+02:00) Africa/Kigali"
    },
    {
        "name": "Africa/Lubumbashi",
        "offset": 120,
        "label": "(GMT+02:00) Africa/Lubumbashi"
    },
    {
        "name": "Africa/Lusaka",
        "offset": 120,
        "label": "(GMT+02:00) Africa/Lusaka"
    },
    {
        "name": "Africa/Maputo",
        "offset": 120,
        "label": "(GMT+02:00) Africa/Maputo"
    },
    {
        "name": "Africa/Maseru",
        "offset": 120,
        "label": "(GMT+02:00) Africa/Maseru"
    },
    {
        "name": "Africa/Mbabane",
        "offset": 120,
        "label": "(GMT+02:00) Africa/Mbabane"
    },
    {
        "name": "Africa/Tripoli",
        "offset": 120,
        "label": "(GMT+02:00) Africa/Tripoli"
    },
    {
        "name": "Africa/Windhoek",
        "offset": 120,
        "label": "(GMT+02:00) Africa/Windhoek"
    },
    {
        "name": "Antarctica/Troll",
        "offset": 120,
        "label": "(GMT+02:00) Antarctica/Troll"
    },
    {
        "name": "Arctic/Longyearbyen",
        "offset": 120,
        "label": "(GMT+02:00) Arctic/Longyearbyen"
    },
    {
        "name": "Atlantic/Jan_Mayen",
        "offset": 120,
        "label": "(GMT+02:00) Atlantic/Jan_Mayen"
    },
    {
        "name": "CET",
        "offset": 120,
        "label": "(GMT+02:00) CET"
    },
    {
        "name": "Etc/GMT-2",
        "offset": 120,
        "label": "(GMT+02:00) Etc/GMT-2"
    },
    {
        "name": "Europe/Amsterdam",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Amsterdam"
    },
    {
        "name": "Europe/Andorra",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Andorra"
    },
    {
        "name": "Europe/Belgrade",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Belgrade"
    },
    {
        "name": "Europe/Berlin",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Berlin"
    },
    {
        "name": "Europe/Bratislava",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Bratislava"
    },
    {
        "name": "Europe/Brussels",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Brussels"
    },
    {
        "name": "Europe/Budapest",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Budapest"
    },
    {
        "name": "Europe/Busingen",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Busingen"
    },
    {
        "name": "Europe/Copenhagen",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Copenhagen"
    },
    {
        "name": "Europe/Gibraltar",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Gibraltar"
    },
    {
        "name": "Europe/Kaliningrad",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Kaliningrad"
    },
    {
        "name": "Europe/Ljubljana",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Ljubljana"
    },
    {
        "name": "Europe/Luxembourg",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Luxembourg"
    },
    {
        "name": "Europe/Madrid",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Madrid"
    },
    {
        "name": "Europe/Malta",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Malta"
    },
    {
        "name": "Europe/Monaco",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Monaco"
    },
    {
        "name": "Europe/Oslo",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Oslo"
    },
    {
        "name": "Europe/Paris",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Paris"
    },
    {
        "name": "Europe/Podgorica",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Podgorica"
    },
    {
        "name": "Europe/Prague",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Prague"
    },
    {
        "name": "Europe/Rome",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Rome"
    },
    {
        "name": "Europe/San_Marino",
        "offset": 120,
        "label": "(GMT+02:00) Europe/San_Marino"
    },
    {
        "name": "Europe/Sarajevo",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Sarajevo"
    },
    {
        "name": "Europe/Skopje",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Skopje"
    },
    {
        "name": "Europe/Stockholm",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Stockholm"
    },
    {
        "name": "Europe/Tirane",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Tirane"
    },
    {
        "name": "Europe/Vaduz",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Vaduz"
    },
    {
        "name": "Europe/Vatican",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Vatican"
    },
    {
        "name": "Europe/Vienna",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Vienna"
    },
    {
        "name": "Europe/Warsaw",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Warsaw"
    },
    {
        "name": "Europe/Zagreb",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Zagreb"
    },
    {
        "name": "Europe/Zurich",
        "offset": 120,
        "label": "(GMT+02:00) Europe/Zurich"
    },
    {
        "name": "Libya",
        "offset": 120,
        "label": "(GMT+02:00) Libya"
    },
    {
        "name": "MET",
        "offset": 120,
        "label": "(GMT+02:00) MET"
    },
    {
        "name": "Poland",
        "offset": 120,
        "label": "(GMT+02:00) Poland"
    },
    {
        "name": "Africa/Addis_Ababa",
        "offset": 180,
        "label": "(GMT+03:00) Africa/Addis_Ababa"
    },
    {
        "name": "Africa/Asmara",
        "offset": 180,
        "label": "(GMT+03:00) Africa/Asmara"
    },
    {
        "name": "Africa/Asmera",
        "offset": 180,
        "label": "(GMT+03:00) Africa/Asmera"
    },
    {
        "name": "Africa/Cairo",
        "offset": 180,
        "label": "(GMT+03:00) Africa/Cairo"
    },
    {
        "name": "Africa/Dar_es_Salaam",
        "offset": 180,
        "label": "(GMT+03:00) Africa/Dar_es_Salaam"
    },
    {
        "name": "Africa/Djibouti",
        "offset": 180,
        "label": "(GMT+03:00) Africa/Djibouti"
    },
    {
        "name": "Africa/Kampala",
        "offset": 180,
        "label": "(GMT+03:00) Africa/Kampala"
    },
    {
        "name": "Africa/Mogadishu",
        "offset": 180,
        "label": "(GMT+03:00) Africa/Mogadishu"
    },
    {
        "name": "Africa/Nairobi",
        "offset": 180,
        "label": "(GMT+03:00) Africa/Nairobi"
    },
    {
        "name": "Antarctica/Syowa",
        "offset": 180,
        "label": "(GMT+03:00) Antarctica/Syowa"
    },
    {
        "name": "Asia/Aden",
        "offset": 180,
        "label": "(GMT+03:00) Asia/Aden"
    },
    {
        "name": "Asia/Amman",
        "offset": 180,
        "label": "(GMT+03:00) Asia/Amman"
    },
    {
        "name": "Asia/Baghdad",
        "offset": 180,
        "label": "(GMT+03:00) Asia/Baghdad"
    },
    {
        "name": "Asia/Bahrain",
        "offset": 180,
        "label": "(GMT+03:00) Asia/Bahrain"
    },
    {
        "name": "Asia/Beirut",
        "offset": 180,
        "label": "(GMT+03:00) Asia/Beirut"
    },
    {
        "name": "Asia/Damascus",
        "offset": 180,
        "label": "(GMT+03:00) Asia/Damascus"
    },
    {
        "name": "Asia/Famagusta",
        "offset": 180,
        "label": "(GMT+03:00) Asia/Famagusta"
    },
    {
        "name": "Asia/Gaza",
        "offset": 180,
        "label": "(GMT+03:00) Asia/Gaza"
    },
    {
        "name": "Asia/Hebron",
        "offset": 180,
        "label": "(GMT+03:00) Asia/Hebron"
    },
    {
        "name": "Asia/Istanbul",
        "offset": 180,
        "label": "(GMT+03:00) Asia/Istanbul"
    },
    {
        "name": "Asia/Jerusalem",
        "offset": 180,
        "label": "(GMT+03:00) Asia/Jerusalem"
    },
    {
        "name": "Asia/Kuwait",
        "offset": 180,
        "label": "(GMT+03:00) Asia/Kuwait"
    },
    {
        "name": "Asia/Nicosia",
        "offset": 180,
        "label": "(GMT+03:00) Asia/Nicosia"
    },
    {
        "name": "Asia/Qatar",
        "offset": 180,
        "label": "(GMT+03:00) Asia/Qatar"
    },
    {
        "name": "Asia/Riyadh",
        "offset": 180,
        "label": "(GMT+03:00) Asia/Riyadh"
    },
    {
        "name": "Asia/Tel_Aviv",
        "offset": 180,
        "label": "(GMT+03:00) Asia/Tel_Aviv"
    },
    {
        "name": "EET",
        "offset": 180,
        "label": "(GMT+03:00) EET"
    },
    {
        "name": "Egypt",
        "offset": 180,
        "label": "(GMT+03:00) Egypt"
    },
    {
        "name": "Etc/GMT-3",
        "offset": 180,
        "label": "(GMT+03:00) Etc/GMT-3"
    },
    {
        "name": "Europe/Athens",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Athens"
    },
    {
        "name": "Europe/Bucharest",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Bucharest"
    },
    {
        "name": "Europe/Chisinau",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Chisinau"
    },
    {
        "name": "Europe/Helsinki",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Helsinki"
    },
    {
        "name": "Europe/Istanbul",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Istanbul"
    },
    {
        "name": "Europe/Kiev",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Kiev"
    },
    {
        "name": "Europe/Kirov",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Kirov"
    },
    {
        "name": "Europe/Kyiv",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Kyiv"
    },
    {
        "name": "Europe/Mariehamn",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Mariehamn"
    },
    {
        "name": "Europe/Minsk",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Minsk"
    },
    {
        "name": "Europe/Moscow",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Moscow"
    },
    {
        "name": "Europe/Nicosia",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Nicosia"
    },
    {
        "name": "Europe/Riga",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Riga"
    },
    {
        "name": "Europe/Simferopol",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Simferopol"
    },
    {
        "name": "Europe/Sofia",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Sofia"
    },
    {
        "name": "Europe/Tallinn",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Tallinn"
    },
    {
        "name": "Europe/Tiraspol",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Tiraspol"
    },
    {
        "name": "Europe/Uzhgorod",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Uzhgorod"
    },
    {
        "name": "Europe/Vilnius",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Vilnius"
    },
    {
        "name": "Europe/Volgograd",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Volgograd"
    },
    {
        "name": "Europe/Zaporozhye",
        "offset": 180,
        "label": "(GMT+03:00) Europe/Zaporozhye"
    },
    {
        "name": "Indian/Antananarivo",
        "offset": 180,
        "label": "(GMT+03:00) Indian/Antananarivo"
    },
    {
        "name": "Indian/Comoro",
        "offset": 180,
        "label": "(GMT+03:00) Indian/Comoro"
    },
    {
        "name": "Indian/Mayotte",
        "offset": 180,
        "label": "(GMT+03:00) Indian/Mayotte"
    },
    {
        "name": "Israel",
        "offset": 180,
        "label": "(GMT+03:00) Israel"
    },
    {
        "name": "Turkey",
        "offset": 180,
        "label": "(GMT+03:00) Turkey"
    },
    {
        "name": "W-SU",
        "offset": 180,
        "label": "(GMT+03:00) W-SU"
    },
    {
        "name": "Asia/Tehran",
        "offset": 210,
        "label": "(GMT+03:30) Asia/Tehran"
    },
    {
        "name": "Iran",
        "offset": 210,
        "label": "(GMT+03:30) Iran"
    },
    {
        "name": "Asia/Baku",
        "offset": 240,
        "label": "(GMT+04:00) Asia/Baku"
    },
    {
        "name": "Asia/Dubai",
        "offset": 240,
        "label": "(GMT+04:00) Asia/Dubai"
    },
    {
        "name": "Asia/Muscat",
        "offset": 240,
        "label": "(GMT+04:00) Asia/Muscat"
    },
    {
        "name": "Asia/Tbilisi",
        "offset": 240,
        "label": "(GMT+04:00) Asia/Tbilisi"
    },
    {
        "name": "Asia/Yerevan",
        "offset": 240,
        "label": "(GMT+04:00) Asia/Yerevan"
    },
    {
        "name": "Etc/GMT-4",
        "offset": 240,
        "label": "(GMT+04:00) Etc/GMT-4"
    },
    {
        "name": "Europe/Astrakhan",
        "offset": 240,
        "label": "(GMT+04:00) Europe/Astrakhan"
    },
    {
        "name": "Europe/Samara",
        "offset": 240,
        "label": "(GMT+04:00) Europe/Samara"
    },
    {
        "name": "Europe/Saratov",
        "offset": 240,
        "label": "(GMT+04:00) Europe/Saratov"
    },
    {
        "name": "Europe/Ulyanovsk",
        "offset": 240,
        "label": "(GMT+04:00) Europe/Ulyanovsk"
    },
    {
        "name": "Indian/Mahe",
        "offset": 240,
        "label": "(GMT+04:00) Indian/Mahe"
    },
    {
        "name": "Indian/Mauritius",
        "offset": 240,
        "label": "(GMT+04:00) Indian/Mauritius"
    },
    {
        "name": "Indian/Reunion",
        "offset": 240,
        "label": "(GMT+04:00) Indian/Reunion"
    },
    {
        "name": "Asia/Kabul",
        "offset": 270,
        "label": "(GMT+04:30) Asia/Kabul"
    },
    {
        "name": "Antarctica/Mawson",
        "offset": 300,
        "label": "(GMT+05:00) Antarctica/Mawson"
    },
    {
        "name": "Asia/Aqtau",
        "offset": 300,
        "label": "(GMT+05:00) Asia/Aqtau"
    },
    {
        "name": "Asia/Aqtobe",
        "offset": 300,
        "label": "(GMT+05:00) Asia/Aqtobe"
    },
    {
        "name": "Asia/Ashgabat",
        "offset": 300,
        "label": "(GMT+05:00) Asia/Ashgabat"
    },
    {
        "name": "Asia/Ashkhabad",
        "offset": 300,
        "label": "(GMT+05:00) Asia/Ashkhabad"
    },
    {
        "name": "Asia/Atyrau",
        "offset": 300,
        "label": "(GMT+05:00) Asia/Atyrau"
    },
    {
        "name": "Asia/Dushanbe",
        "offset": 300,
        "label": "(GMT+05:00) Asia/Dushanbe"
    },
    {
        "name": "Asia/Karachi",
        "offset": 300,
        "label": "(GMT+05:00) Asia/Karachi"
    },
    {
        "name": "Asia/Oral",
        "offset": 300,
        "label": "(GMT+05:00) Asia/Oral"
    },
    {
        "name": "Asia/Qyzylorda",
        "offset": 300,
        "label": "(GMT+05:00) Asia/Qyzylorda"
    },
    {
        "name": "Asia/Samarkand",
        "offset": 300,
        "label": "(GMT+05:00) Asia/Samarkand"
    },
    {
        "name": "Asia/Tashkent",
        "offset": 300,
        "label": "(GMT+05:00) Asia/Tashkent"
    },
    {
        "name": "Asia/Yekaterinburg",
        "offset": 300,
        "label": "(GMT+05:00) Asia/Yekaterinburg"
    },
    {
        "name": "Etc/GMT-5",
        "offset": 300,
        "label": "(GMT+05:00) Etc/GMT-5"
    },
    {
        "name": "Indian/Kerguelen",
        "offset": 300,
        "label": "(GMT+05:00) Indian/Kerguelen"
    },
    {
        "name": "Indian/Maldives",
        "offset": 300,
        "label": "(GMT+05:00) Indian/Maldives"
    },
    {
        "name": "Asia/Calcutta",
        "offset": 330,
        "label": "(GMT+05:30) Asia/Calcutta"
    },
    {
        "name": "Asia/Colombo",
        "offset": 330,
        "label": "(GMT+05:30) Asia/Colombo"
    },
    {
        "name": "Asia/Kolkata",
        "offset": 330,
        "label": "(GMT+05:30) Asia/Kolkata"
    },
    {
        "name": "Asia/Kathmandu",
        "offset": 345,
        "label": "(GMT+05:45) Asia/Kathmandu"
    },
    {
        "name": "Asia/Katmandu",
        "offset": 345,
        "label": "(GMT+05:45) Asia/Katmandu"
    },
    {
        "name": "Antarctica/Vostok",
        "offset": 360,
        "label": "(GMT+06:00) Antarctica/Vostok"
    },
    {
        "name": "Asia/Almaty",
        "offset": 360,
        "label": "(GMT+06:00) Asia/Almaty"
    },
    {
        "name": "Asia/Bishkek",
        "offset": 360,
        "label": "(GMT+06:00) Asia/Bishkek"
    },
    {
        "name": "Asia/Dacca",
        "offset": 360,
        "label": "(GMT+06:00) Asia/Dacca"
    },
    {
        "name": "Asia/Dhaka",
        "offset": 360,
        "label": "(GMT+06:00) Asia/Dhaka"
    },
    {
        "name": "Asia/Kashgar",
        "offset": 360,
        "label": "(GMT+06:00) Asia/Kashgar"
    },
    {
        "name": "Asia/Omsk",
        "offset": 360,
        "label": "(GMT+06:00) Asia/Omsk"
    },
    {
        "name": "Asia/Qostanay",
        "offset": 360,
        "label": "(GMT+06:00) Asia/Qostanay"
    },
    {
        "name": "Asia/Thimbu",
        "offset": 360,
        "label": "(GMT+06:00) Asia/Thimbu"
    },
    {
        "name": "Asia/Thimphu",
        "offset": 360,
        "label": "(GMT+06:00) Asia/Thimphu"
    },
    {
        "name": "Asia/Urumqi",
        "offset": 360,
        "label": "(GMT+06:00) Asia/Urumqi"
    },
    {
        "name": "Etc/GMT-6",
        "offset": 360,
        "label": "(GMT+06:00) Etc/GMT-6"
    },
    {
        "name": "Indian/Chagos",
        "offset": 360,
        "label": "(GMT+06:00) Indian/Chagos"
    },
    {
        "name": "Asia/Rangoon",
        "offset": 390,
        "label": "(GMT+06:30) Asia/Rangoon"
    },
    {
        "name": "Asia/Yangon",
        "offset": 390,
        "label": "(GMT+06:30) Asia/Yangon"
    },
    {
        "name": "Indian/Cocos",
        "offset": 390,
        "label": "(GMT+06:30) Indian/Cocos"
    },
    {
        "name": "Antarctica/Davis",
        "offset": 420,
        "label": "(GMT+07:00) Antarctica/Davis"
    },
    {
        "name": "Asia/Bangkok",
        "offset": 420,
        "label": "(GMT+07:00) Asia/Bangkok"
    },
    {
        "name": "Asia/Barnaul",
        "offset": 420,
        "label": "(GMT+07:00) Asia/Barnaul"
    },
    {
        "name": "Asia/Ho_Chi_Minh",
        "offset": 420,
        "label": "(GMT+07:00) Asia/Ho_Chi_Minh"
    },
    {
        "name": "Asia/Hovd",
        "offset": 420,
        "label": "(GMT+07:00) Asia/Hovd"
    },
    {
        "name": "Asia/Jakarta",
        "offset": 420,
        "label": "(GMT+07:00) Asia/Jakarta"
    },
    {
        "name": "Asia/Krasnoyarsk",
        "offset": 420,
        "label": "(GMT+07:00) Asia/Krasnoyarsk"
    },
    {
        "name": "Asia/Novokuznetsk",
        "offset": 420,
        "label": "(GMT+07:00) Asia/Novokuznetsk"
    },
    {
        "name": "Asia/Novosibirsk",
        "offset": 420,
        "label": "(GMT+07:00) Asia/Novosibirsk"
    },
    {
        "name": "Asia/Phnom_Penh",
        "offset": 420,
        "label": "(GMT+07:00) Asia/Phnom_Penh"
    },
    {
        "name": "Asia/Pontianak",
        "offset": 420,
        "label": "(GMT+07:00) Asia/Pontianak"
    },
    {
        "name": "Asia/Saigon",
        "offset": 420,
        "label": "(GMT+07:00) Asia/Saigon"
    },
    {
        "name": "Asia/Tomsk",
        "offset": 420,
        "label": "(GMT+07:00) Asia/Tomsk"
    },
    {
        "name": "Asia/Vientiane",
        "offset": 420,
        "label": "(GMT+07:00) Asia/Vientiane"
    },
    {
        "name": "Etc/GMT-7",
        "offset": 420,
        "label": "(GMT+07:00) Etc/GMT-7"
    },
    {
        "name": "Indian/Christmas",
        "offset": 420,
        "label": "(GMT+07:00) Indian/Christmas"
    },
    {
        "name": "Asia/Brunei",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Brunei"
    },
    {
        "name": "Asia/Choibalsan",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Choibalsan"
    },
    {
        "name": "Asia/Chongqing",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Chongqing"
    },
    {
        "name": "Asia/Chungking",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Chungking"
    },
    {
        "name": "Asia/Harbin",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Harbin"
    },
    {
        "name": "Asia/Hong_Kong",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Hong_Kong"
    },
    {
        "name": "Asia/Irkutsk",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Irkutsk"
    },
    {
        "name": "Asia/Kuala_Lumpur",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Kuala_Lumpur"
    },
    {
        "name": "Asia/Kuching",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Kuching"
    },
    {
        "name": "Asia/Macao",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Macao"
    },
    {
        "name": "Asia/Macau",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Macau"
    },
    {
        "name": "Asia/Makassar",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Makassar"
    },
    {
        "name": "Asia/Manila",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Manila"
    },
    {
        "name": "Asia/Shanghai",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Shanghai"
    },
    {
        "name": "Asia/Singapore",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Singapore"
    },
    {
        "name": "Asia/Taipei",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Taipei"
    },
    {
        "name": "Asia/Ujung_Pandang",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Ujung_Pandang"
    },
    {
        "name": "Asia/Ulaanbaatar",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Ulaanbaatar"
    },
    {
        "name": "Asia/Ulan_Bator",
        "offset": 480,
        "label": "(GMT+08:00) Asia/Ulan_Bator"
    },
    {
        "name": "Australia/Perth",
        "offset": 480,
        "label": "(GMT+08:00) Australia/Perth"
    },
    {
        "name": "Australia/West",
        "offset": 480,
        "label": "(GMT+08:00) Australia/West"
    },
    {
        "name": "Etc/GMT-8",
        "offset": 480,
        "label": "(GMT+08:00) Etc/GMT-8"
    },
    {
        "name": "Hongkong",
        "offset": 480,
        "label": "(GMT+08:00) Hongkong"
    },
    {
        "name": "PRC",
        "offset": 480,
        "label": "(GMT+08:00) PRC"
    },
    {
        "name": "ROC",
        "offset": 480,
        "label": "(GMT+08:00) ROC"
    },
    {
        "name": "Singapore",
        "offset": 480,
        "label": "(GMT+08:00) Singapore"
    },
    {
        "name": "Australia/Eucla",
        "offset": 525,
        "label": "(GMT+08:45) Australia/Eucla"
    },
    {
        "name": "Asia/Chita",
        "offset": 540,
        "label": "(GMT+09:00) Asia/Chita"
    },
    {
        "name": "Asia/Dili",
        "offset": 540,
        "label": "(GMT+09:00) Asia/Dili"
    },
    {
        "name": "Asia/Jayapura",
        "offset": 540,
        "label": "(GMT+09:00) Asia/Jayapura"
    },
    {
        "name": "Asia/Khandyga",
        "offset": 540,
        "label": "(GMT+09:00) Asia/Khandyga"
    },
    {
        "name": "Asia/Pyongyang",
        "offset": 540,
        "label": "(GMT+09:00) Asia/Pyongyang"
    },
    {
        "name": "Asia/Seoul",
        "offset": 540,
        "label": "(GMT+09:00) Asia/Seoul"
    },
    {
        "name": "Asia/Tokyo",
        "offset": 540,
        "label": "(GMT+09:00) Asia/Tokyo"
    },
    {
        "name": "Asia/Yakutsk",
        "offset": 540,
        "label": "(GMT+09:00) Asia/Yakutsk"
    },
    {
        "name": "Etc/GMT-9",
        "offset": 540,
        "label": "(GMT+09:00) Etc/GMT-9"
    },
    {
        "name": "Japan",
        "offset": 540,
        "label": "(GMT+09:00) Japan"
    },
    {
        "name": "Pacific/Palau",
        "offset": 540,
        "label": "(GMT+09:00) Pacific/Palau"
    },
    {
        "name": "ROK",
        "offset": 540,
        "label": "(GMT+09:00) ROK"
    },
    {
        "name": "Australia/Adelaide",
        "offset": 570,
        "label": "(GMT+09:30) Australia/Adelaide"
    },
    {
        "name": "Australia/Broken_Hill",
        "offset": 570,
        "label": "(GMT+09:30) Australia/Broken_Hill"
    },
    {
        "name": "Australia/Darwin",
        "offset": 570,
        "label": "(GMT+09:30) Australia/Darwin"
    },
    {
        "name": "Australia/North",
        "offset": 570,
        "label": "(GMT+09:30) Australia/North"
    },
    {
        "name": "Australia/South",
        "offset": 570,
        "label": "(GMT+09:30) Australia/South"
    },
    {
        "name": "Australia/Yancowinna",
        "offset": 570,
        "label": "(GMT+09:30) Australia/Yancowinna"
    },
    {
        "name": "Antarctica/DumontDUrville",
        "offset": 600,
        "label": "(GMT+10:00) Antarctica/DumontDUrville"
    },
    {
        "name": "Antarctica/Macquarie",
        "offset": 600,
        "label": "(GMT+10:00) Antarctica/Macquarie"
    },
    {
        "name": "Asia/Ust-Nera",
        "offset": 600,
        "label": "(GMT+10:00) Asia/Ust-Nera"
    },
    {
        "name": "Asia/Vladivostok",
        "offset": 600,
        "label": "(GMT+10:00) Asia/Vladivostok"
    },
    {
        "name": "Australia/ACT",
        "offset": 600,
        "label": "(GMT+10:00) Australia/ACT"
    },
    {
        "name": "Australia/Brisbane",
        "offset": 600,
        "label": "(GMT+10:00) Australia/Brisbane"
    },
    {
        "name": "Australia/Canberra",
        "offset": 600,
        "label": "(GMT+10:00) Australia/Canberra"
    },
    {
        "name": "Australia/Currie",
        "offset": 600,
        "label": "(GMT+10:00) Australia/Currie"
    },
    {
        "name": "Australia/Hobart",
        "offset": 600,
        "label": "(GMT+10:00) Australia/Hobart"
    },
    {
        "name": "Australia/Lindeman",
        "offset": 600,
        "label": "(GMT+10:00) Australia/Lindeman"
    },
    {
        "name": "Australia/Melbourne",
        "offset": 600,
        "label": "(GMT+10:00) Australia/Melbourne"
    },
    {
        "name": "Australia/NSW",
        "offset": 600,
        "label": "(GMT+10:00) Australia/NSW"
    },
    {
        "name": "Australia/Queensland",
        "offset": 600,
        "label": "(GMT+10:00) Australia/Queensland"
    },
    {
        "name": "Australia/Sydney",
        "offset": 600,
        "label": "(GMT+10:00) Australia/Sydney"
    },
    {
        "name": "Australia/Tasmania",
        "offset": 600,
        "label": "(GMT+10:00) Australia/Tasmania"
    },
    {
        "name": "Australia/Victoria",
        "offset": 600,
        "label": "(GMT+10:00) Australia/Victoria"
    },
    {
        "name": "Etc/GMT-10",
        "offset": 600,
        "label": "(GMT+10:00) Etc/GMT-10"
    },
    {
        "name": "Pacific/Chuuk",
        "offset": 600,
        "label": "(GMT+10:00) Pacific/Chuuk"
    },
    {
        "name": "Pacific/Guam",
        "offset": 600,
        "label": "(GMT+10:00) Pacific/Guam"
    },
    {
        "name": "Pacific/Port_Moresby",
        "offset": 600,
        "label": "(GMT+10:00) Pacific/Port_Moresby"
    },
    {
        "name": "Pacific/Saipan",
        "offset": 600,
        "label": "(GMT+10:00) Pacific/Saipan"
    },
    {
        "name": "Pacific/Truk",
        "offset": 600,
        "label": "(GMT+10:00) Pacific/Truk"
    },
    {
        "name": "Pacific/Yap",
        "offset": 600,
        "label": "(GMT+10:00) Pacific/Yap"
    },
    {
        "name": "Australia/LHI",
        "offset": 630,
        "label": "(GMT+10:30) Australia/LHI"
    },
    {
        "name": "Australia/Lord_Howe",
        "offset": 630,
        "label": "(GMT+10:30) Australia/Lord_Howe"
    },
    {
        "name": "Antarctica/Casey",
        "offset": 660,
        "label": "(GMT+11:00) Antarctica/Casey"
    },
    {
        "name": "Asia/Magadan",
        "offset": 660,
        "label": "(GMT+11:00) Asia/Magadan"
    },
    {
        "name": "Asia/Sakhalin",
        "offset": 660,
        "label": "(GMT+11:00) Asia/Sakhalin"
    },
    {
        "name": "Asia/Srednekolymsk",
        "offset": 660,
        "label": "(GMT+11:00) Asia/Srednekolymsk"
    },
    {
        "name": "Etc/GMT-11",
        "offset": 660,
        "label": "(GMT+11:00) Etc/GMT-11"
    },
    {
        "name": "Pacific/Bougainville",
        "offset": 660,
        "label": "(GMT+11:00) Pacific/Bougainville"
    },
    {
        "name": "Pacific/Efate",
        "offset": 660,
        "label": "(GMT+11:00) Pacific/Efate"
    },
    {
        "name": "Pacific/Guadalcanal",
        "offset": 660,
        "label": "(GMT+11:00) Pacific/Guadalcanal"
    },
    {
        "name": "Pacific/Kosrae",
        "offset": 660,
        "label": "(GMT+11:00) Pacific/Kosrae"
    },
    {
        "name": "Pacific/Norfolk",
        "offset": 660,
        "label": "(GMT+11:00) Pacific/Norfolk"
    },
    {
        "name": "Pacific/Noumea",
        "offset": 660,
        "label": "(GMT+11:00) Pacific/Noumea"
    },
    {
        "name": "Pacific/Pohnpei",
        "offset": 660,
        "label": "(GMT+11:00) Pacific/Pohnpei"
    },
    {
        "name": "Pacific/Ponape",
        "offset": 660,
        "label": "(GMT+11:00) Pacific/Ponape"
    },
    {
        "name": "Antarctica/McMurdo",
        "offset": 720,
        "label": "(GMT+12:00) Antarctica/McMurdo"
    },
    {
        "name": "Antarctica/South_Pole",
        "offset": 720,
        "label": "(GMT+12:00) Antarctica/South_Pole"
    },
    {
        "name": "Asia/Anadyr",
        "offset": 720,
        "label": "(GMT+12:00) Asia/Anadyr"
    },
    {
        "name": "Asia/Kamchatka",
        "offset": 720,
        "label": "(GMT+12:00) Asia/Kamchatka"
    },
    {
        "name": "Etc/GMT-12",
        "offset": 720,
        "label": "(GMT+12:00) Etc/GMT-12"
    },
    {
        "name": "Kwajalein",
        "offset": 720,
        "label": "(GMT+12:00) Kwajalein"
    },
    {
        "name": "NZ",
        "offset": 720,
        "label": "(GMT+12:00) NZ"
    },
    {
        "name": "Pacific/Auckland",
        "offset": 720,
        "label": "(GMT+12:00) Pacific/Auckland"
    },
    {
        "name": "Pacific/Fiji",
        "offset": 720,
        "label": "(GMT+12:00) Pacific/Fiji"
    },
    {
        "name": "Pacific/Funafuti",
        "offset": 720,
        "label": "(GMT+12:00) Pacific/Funafuti"
    },
    {
        "name": "Pacific/Kwajalein",
        "offset": 720,
        "label": "(GMT+12:00) Pacific/Kwajalein"
    },
    {
        "name": "Pacific/Majuro",
        "offset": 720,
        "label": "(GMT+12:00) Pacific/Majuro"
    },
    {
        "name": "Pacific/Nauru",
        "offset": 720,
        "label": "(GMT+12:00) Pacific/Nauru"
    },
    {
        "name": "Pacific/Tarawa",
        "offset": 720,
        "label": "(GMT+12:00) Pacific/Tarawa"
    },
    {
        "name": "Pacific/Wake",
        "offset": 720,
        "label": "(GMT+12:00) Pacific/Wake"
    },
    {
        "name": "Pacific/Wallis",
        "offset": 720,
        "label": "(GMT+12:00) Pacific/Wallis"
    },
    {
        "name": "NZ-CHAT",
        "offset": 765,
        "label": "(GMT+12:45) NZ-CHAT"
    },
    {
        "name": "Pacific/Chatham",
        "offset": 765,
        "label": "(GMT+12:45) Pacific/Chatham"
    },
    {
        "name": "Etc/GMT-13",
        "offset": 780,
        "label": "(GMT+13:00) Etc/GMT-13"
    },
    {
        "name": "Pacific/Apia",
        "offset": 780,
        "label": "(GMT+13:00) Pacific/Apia"
    },
    {
        "name": "Pacific/Enderbury",
        "offset": 780,
        "label": "(GMT+13:00) Pacific/Enderbury"
    },
    {
        "name": "Pacific/Fakaofo",
        "offset": 780,
        "label": "(GMT+13:00) Pacific/Fakaofo"
    },
    {
        "name": "Pacific/Kanton",
        "offset": 780,
        "label": "(GMT+13:00) Pacific/Kanton"
    },
    {
        "name": "Pacific/Tongatapu",
        "offset": 780,
        "label": "(GMT+13:00) Pacific/Tongatapu"
    },
    {
        "name": "Etc/GMT-14",
        "offset": 840,
        "label": "(GMT+14:00) Etc/GMT-14"
    },
    {
        "name": "Pacific/Kiritimati",
        "offset": 840,
        "label": "(GMT+14:00) Pacific/Kiritimati"
    }
]