<template>
  <img :src="src" :alt="alt" :class="`h-${height} w-${width} rounded-${rounded} ${customClass}`" />
</template>

<script>
/**
- Use for img with text
- options to customize size,color, content,alignment,transformation, decoration
**/

export default {
  name: 'Image',
  props: {
    /**
      sets url of image resource. Default is #
      */
    src: {
      type: String,
      default: '#'
    },
    /**
      set alt of image resource default is ''
      */ 
    alt: {
      type: String,
      default: ''
    },

    /**
     * set height of image resource, default is 8
     */
    height: {
      type: Number,
      default: 8
    },

     /**
     * set width of image resource, default is 8
     */
    width: {
      type: Number,
      default: 8
    },

    /**
     * set image as rounded image default is "none"
     */
    rounded: {
      type: String,
      default: "none"
    },

    /**
     * additional classes default is ""
     */
     customClass: {
      type: String,
      default: ""
    }
  },
};
</script>