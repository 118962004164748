<template>
  <GalleryTemplate
    :headerProps="headerProps"
    :socialProps="socialProps"
    :contentInfo="contentInfo"
  />
</template>

<script>
import GalleryTemplate from "@/components/templates/Gallery/Gallery";
import { headerProps, socialProps } from "@/components/constants.js";

export default {
  components: {
    GalleryTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      socialProps: socialProps,
      contentInfo: {
        title: "Visually explore our venue",
        desc: `
            <p>Welcome to our photo tour, where we invite you to indulge in a visual feast of the exquisite beauty that awaits you at Starlight Ballroom. Browse through our captivating gallery and immerse yourself in the grandeur of our elegant spaces, from the stunning main hall adorned with sparkling chandeliers to the enchanting outdoor gardens bathed in soft, romantic lighting. Witness the meticulous attention to detail in our tasteful decor and imagine the possibilities for your own event. With each photo, you'll discover the essence of luxury and sophistication that sets us apart. Let our photo tour be your window into the world of extraordinary events, and allow yourself to envision the magic that can be created within our magnificent venue.</p>
        `,
      },
    };
  },
};
</script>

<style scoped></style>
